




















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { GuideStore } from '@/themes/v1/stores/guide.store';
import { getModule } from 'vuex-module-decorators';
import Popup from '@/themes/v1/components/Popup.vue';
import Box from '@/themes/v1/components/Box/Box.vue';
import BoxIcon from '@/themes/v1/components/Box/BoxIcon.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import ArticleContent from '@/themes/v1/components/ArticleContent/ArticleContent.vue';
import HelpfulQuest from '@/themes/v1/components/HelpfulQuest.vue';
import { Review } from '@/modules/guide/reviews';
import { LanguageCode } from '@/core/system';
import Carousel from '@/themes/v1/components/Carousel/Carousel.vue';
import CarouselItem from '@/themes/v1/components/Carousel/CarouselItem.vue';
import ScrollBox from '@/themes/v1/components/ScrollBox.vue';
import QuestionMark from '@/themes/v1/components/QuestionMark.vue';
import { WithMeta } from '@/core/vue.types';
import { MetaInfo } from 'vue-meta';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ClickOutside = require('vue-click-outside');

@Component({
  components: {
    ScrollBox,
    CarouselItem,
    Carousel,
    HelpfulQuest,
    ArticleContent,
    BoxContent,
    BoxIcon,
    Box,
    Popup,
    QuestionMark,
  },
  directives: { ClickOutside },
})
export default class FaqPopup extends Vue implements WithMeta {
  guideStore = getModule(GuideStore, this.$store);

  @Prop()
  article: any;

  @Prop()
  readonly reviews!: { [path: string]: Review };

  @Prop()
  readonly language!: LanguageCode;

  close() {
    this.$emit('close');
  }

  saveReview(review: Review) {
    this.$emit('changeReview', review);
  }

  metaInfo(): MetaInfo {
    const section = this.guideStore.section;
    if (section && this.article) {
      return {
        title: section.articlePageMetadata.title.replace(
          /{title}/g,
          this.article.metadata.title
        ),
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: section.articlePageMetadata.description.replace(
              /{description}/g,
              this.article.metadata.description
            ),
          },
          {
            vmid: 'keywords',
            name: 'keywords',
            content: section.articlePageMetadata.keywords
              .replace(/{title}/g, this.article.metadata.title)
              .replace(/{keywords}/g, this.article.metadata.keywords),
          },
        ],
      };
    }
    return {
      title: 'Support Portal',
    };
  }
}
