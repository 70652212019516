import { FaqViewGroup } from '@/modules/guide/articles';

function getScore(items: FaqViewGroup[]) {
  return (
    items.length * 2 +
    items.reduce((sum, current) => sum + current.articles.length, 0)
  );
}

export default function (items: FaqViewGroup[]) {
  const baseItems = items.slice(0, Math.floor(items.length / 2) * 2);
  const first = baseItems.filter((x, index) => index % 2 === 0);
  const second = baseItems.filter((x, index) => index % 2 === 1);
  if (items.length > baseItems.length) {
    const remainder = items.slice(baseItems.length);
    while (remainder.length > 0) {
      const target = getScore(first) > getScore(second) ? second : first;
      target.push(remainder.shift() as FaqViewGroup);
    }
  }
  return [first, second];
}
