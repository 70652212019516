






import { Component, Vue, Provide } from 'vue-property-decorator';
import AccordeonPanel from './AccordeonPanel.vue';

@Component
export default class Accordeon extends Vue {
  @Provide('accordeon')
  providedInstance = this;

  selected: AccordeonPanel | null = null;

  open(panel: AccordeonPanel | null): void {
    if (this.selected != null && this.selected !== panel) {
      this.selected.opened = false;
    }
    this.selected = panel;
  }
}
