















import { timeout } from '@/core/utils';
import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import Accordeon from './Accordeon.vue';

@Component
export default class AccordeonPanel extends Vue {
  @Prop() title!: string;
  @Inject('accordeon') accordeon!: Accordeon;

  opened = false;

  onClick() {
    this.opened = !this.opened;
    this.accordeon.open(this.opened ? this : null);
    if (this.opened) {
      timeout(10).then(() => {
        const posY = (this.$refs.panel as HTMLElement).getBoundingClientRect()
          .y;
        const offset = this.$layout().ingame ? -10 : -60;
        window.scrollTo({
          top: pageYOffset + posY + offset,
          behavior: 'smooth',
        });
      });
    }
  }
}
