






















































































import { Component, Vue, Inject } from 'vue-property-decorator';
import { GuideStore } from '@/themes/v1/stores/guide.store';
import { getModule } from 'vuex-module-decorators';
import { SsrCtx, WithAsyncData, WithPrefetch } from '@/core/vue.types';
import { FaqViewGroup } from '@/modules/guide/articles';
import config from '@/env';
import Breadcrumbs from '@/themes/v1/components/Breadcrumbs.vue';
import Box from '@/themes/v1/components/Box/Box.vue';
import BoxIcon from '@/themes/v1/components/Box/BoxIcon.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import AskBox from '@/themes/v1/components/AskBox.vue';
import FaqPopup from '@/themes/v1/views/guide/section/FaqPopup.vue';
import Popup from '@/themes/v1/components/Popup.vue';
import Spinner from '@/themes/v1/components/Spinner.vue';
import Accordeon from '@/themes/v1/components/Accordeon/Accordeon.vue';
import AccordeonPanel from '@/themes/v1/components/Accordeon/AccordeonPanel.vue';
import NeedSomethingElse from '@/themes/v1/components/NeedSomethingElse.vue';

import { Review } from '@/modules/guide/reviews';
import Sticky from '@/themes/v1/components/Sticky.vue';
import { ArticleService } from '@/modules/guide/articles/article.service';
import { ReviewsService } from '@/modules/guide/reviews/reviews.service';
import TwoColumn from '@/themes/v1/components/TwoColumn.vue';
import { AppStore } from '@/themes/v1/stores/app.store';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import {
  universalRedirect,
  pushWithLangAndPlatform,
} from '@/themes/v1/fns/router';
import faqColumnFn from '@/themes/v1/fns/faq-columns';
import QuestionMark from '@/themes/v1/components/QuestionMark.vue';
import { SystemStore } from '@/core/system';

@Component({
  components: {
    TwoColumn,
    Sticky,
    Popup,
    FaqPopup,
    AskBox,
    BoxContent,
    BoxIcon,
    Box,
    Breadcrumbs,
    Spinner,
    Accordeon,
    AccordeonPanel,
    NeedSomethingElse,
    QuestionMark,
  },
})
export default class FaqSection
  extends Vue
  implements WithPrefetch, WithAsyncData {
  @Inject() articleService!: ArticleService;
  @Inject() reviewsService!: ReviewsService;

  guideStore = getModule(GuideStore, this.$store);
  appStore = getModule(AppStore, this.$store);
  systemStore = getModule(SystemStore, this.$store);
  loading = false;
  articleLoading = false;

  get article() {
    return this.guideStore.article;
  }

  get section() {
    return this.appStore.sections['faq'];
  }

  get items() {
    return (this.guideStore.sectionItems as FaqViewGroup[]).filter(
      (x: FaqViewGroup) => x.articles?.length
    );
  }

  getColumns() {
    return faqColumnFn(this.items);
  }

  async asyncData(ctx: SsrCtx) {
    const guideStore = getModule(GuideStore, ctx.store);
    const articleService = resolveFromCtx<ArticleService>(
      'articleService',
      ctx
    );

    const items = await articleService.getFaqViewItems(
      config.game,
      ctx.app.$i18n.locale,
      'faq'
    );

    guideStore.setItems({ segment: 'faq', items });
    if (!items.length) {
      universalRedirect(ctx, '');
      return;
    }

    if (!ctx.route.params.pathMatch) {
      guideStore.setArticle(null);
      return;
    }
    try {
      const path = `/faq${ctx.route.params.pathMatch}`;
      const article = await articleService.getItem(
        config.game,
        ctx.app.$i18n.locale,
        path
      );
      guideStore.setArticle(article);
      return;
    } catch (error) {
      if (error.response.status >= 300 && error.response.status < 310) {
        const redirectedPath = error.response.data;
        const path = ctx.app.$routerHelper.withPlatformAndLang(
          ctx.route,
          redirectedPath
        );
        ctx.redirect({ path }, error.response.status);
        return;
      } else if (error.response.status === 404) {
        ctx.error('Article not found', 404);
        return;
      } else {
        ctx.error(error, 500);
        return;
      }
    }
  }

  async prefetch(): Promise<void> {
    this.guideStore.setSection(this.section);
  }

  async loadQuestion(path) {
    try {
      this.articleLoading = true;
      const article = await this.articleService.getItem(
        config.game,
        this.$i18n.locale,
        path
      );
      if (!article) {
        pushWithLangAndPlatform(this.$router, 'faq');
        return;
      }
      this.guideStore.setArticle(article);
    } finally {
      this.articleLoading = false;
    }
  }

  closePopup() {
    this.guideStore.setArticle(null);
    pushWithLangAndPlatform(this.$router, 'faq');
  }

  // @Watch('$route.params')
  // watchFaqArticlePath(params: Dictionary<string>) {
  //   if (params.pathMatch) {
  //     this.loadQuestion(
  //       `/${this.section.pathSegment}${this.$route.params.pathMatch}`
  //     );
  //   } else {
  //     this.guideStore.setArticle(null);
  //   }
  // }

  saveReview(review: Review) {
    this.guideStore.setReview({ path: review.articlePath, review });
    this.reviewsService.send(review).then((id) => {
      const item = this.guideStore.reviews[review.articlePath];
      this.guideStore.setReview({
        path: review.articlePath,
        review: {
          ...item,
          id,
        },
      });
    });
  }
}
